import styled from "styled-components"

export const Wrapper = styled.header`
  background: #012662;
  width: 100%;
  z-index: 99;
  position: relative;
    .row{
        margin:0;
    }
  .topbar-social {
    ul {
      list-style: none;
      padding: 0;
      margin: 0px;
      display: inline-block;

      li {
        display: inline-block;
        margin: 0;
        border-right: 1px solid #aab8b09c;
        padding: 5px 15px;

        a {
          color: #fff;
        }
      }
    }
    @media (max-width: 991px) {
      text-align: center
    }
  }

  .topbar-contact{
      text-align: right;
      ul{
        list-style: none;
        padding: 0;
        margin: 0px;
        display: inline-block;
  
          li{
            display: inline-block;
            margin: 0;
            padding: 5px 15px;
              a{
                color: #fff;
                font-size: 13px;
              }
              svg{
                  color: #fff;
                  font-size: 12px; 
                  margin-right: 5px;
              }
          }
         
      }
      @media (max-width: 991px) {
        text-align: center
      }
  }
`
