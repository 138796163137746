import * as React from "react"
import { Link } from "gatsby"
import {Container, Row} from "react-bootstrap"
import { FooterWrapper } from "./Footer.styles"
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"

const Footer = () =>{
    return(
        <FooterWrapper>
        <div className="page-footer font-small blue pt-4">
            <Container>
                <Row>
                    <div className="col-md-3 mt-md-0 mt-3">
                    <h5 className="text-uppercase">Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </div>

                    <hr className="clearfix w-100 d-md-none pb-0"/>

                    <div className="col-md-3 mb-md-0 mb-3">
                    <h5 className="text-uppercase">Support</h5>
                        <ul className="list-unstyled">
                            <li><p>+966 138821464</p></li>
                            <li><a href='mailto:info@itmatrix.com'>info@itmatrix.com</a></li>
                        </ul>
                    </div>

                    <div className="col-md-3 mb-md-0 mb-3">
                        <h5 className="text-uppercase">Address</h5>
                        <ul className="list-unstyled">
                            <li><p>Al Khobar,<br />
                                Kingdom of Saudi Arabia</p></li>
                           
                        </ul>
                    </div>
                    <div className="col-md-3 mt-md-0 mt-3 app-icon">
                    <h5 className="text-uppercase">Follow Us</h5>
                        <ul className="list-unstyled follow-us-list">
                        <li>
                <Link
                  to="https://www.facebook.com/Advanced-Matrix-100600602428760/"
                  target="_blank"
                >
                  <FaFacebook />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/AdvancedMatrix_" target="_blank">
                  <FaTwitter />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/1322821/admin/"
                  target="_blank"
                >
                  <FaLinkedin />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/advancedmatrix/"
                  target="_blank"
                >
                  <FaInstagram />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UC_gT7JchBHhE3vyX_gOqSdA"
                  target="_blank"
                >
                  <FaYoutube />
                </Link>
              </li>
                        </ul>
                    </div>
                </Row>
            </Container>

        <div className="footer-copyright text-center">
            <p> Copyright © 2022 Advanced Matrix | Powered by EDXLabs</p>
        </div>

        </div>
</FooterWrapper>

    )
}
export default Footer;