import React, { useState } from "react"

 import Header from "../Header/Header"
 import Hamburger from "../Hamburger/Hamburger"
 import OverlayMenu from "../OverlayMenu/OverlayMenu"
 import TopBar from "../TopBar/TopBar"
 import "@fontsource/montserrat"
 import "@fontsource/montserrat/600.css"
 import "@fontsource/montserrat/700.css"
 import "@fontsource/montserrat/900.css"
 import { GlobalStyles, Primary } from "./Layout.styles"
 import Footer from "../Footer/Footer"

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleOverlayMenu = () => setMenuOpen(prev => !prev)

  return (
    <>
      <GlobalStyles />
      <Hamburger handleOverlayMenu={handleOverlayMenu} />
      <OverlayMenu menuOpen={menuOpen} callback={handleOverlayMenu} />
      <TopBar/>
      <Header />
      <Primary>{children}</Primary>
      <Footer />
    </>
  )
}

export default Layout