import styled from 'styled-components';

export const NavigationWrapper = styled.nav`
font-family: "Montserrat"; 
  text-transform: uppercase;

  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  display: none;
  display: flex;
    justify-content: end;
    align-items: center;
    align-content: center;
    height: 100%;

  .nav-active {
    color: #28c973;
  }
  ul{
    display: flex;
    list-style: none;
    margin-bottom:0;
    align-self: center;
    height:100%;
    align-items: center;
    padding: 15px 0;

    @media (max-width: 991px) {
      align-items: start;
      flex-direction: column;
    }
  }
  

  @media (min-width: 1200px) {
    font-size: 1rem;
  }

  ul li {
    margin: 0 30px 0 0;
    height: auto;
    position: relative;

    a {
      display: flex;
      padding: 0 0 0 10px;
      transition: all 0.2s ease;
      text-decoration: none;
      color: #28c973 !important;
      font-family: "Montserrat", Sans-serif;
      font-size: 14px;

      div {
        margin: -4px 0 0 5px;
      }
    }
    .dropdown:after{
      content: "▼";
      font-size: 12px;
    line-height: 20px;
    color: #17c973a1;
    margin-left: 4px;
    }
    @media (max-width: 991px) {
      margin: 0;
      padding: 8px 0;
    }
  }

  ul li:last-child {
    margin: 0;
    
  }
  #request-demo{

      color: #28c973;
      background: transparent;
      border: 1px solid #28c973;
      display: block;
      height: 30px;
      padding: 0 20px;
      line-height: 30px;
      border-radius: 4px;
      margin-left: 10px;
      font-size: 13px;
      letter-spacing: 1px;
      font-weight: 700;
    
  }

  ul li:hover > ul {
    display: block;
  }

  ul li a:hover {
    color: #ee2562;
  }

  ul ul {
    animation: fadeInMenu 0.3s both ease-in;
    display: none;
    position: absolute;
    left: 0;
    margin: 0;
    top: 22px;
    text-transform: none;
    background: #fff;
    padding: 15px 10px 10px 10px;
    box-shadow: 0px 6px 23px -10px rgba(0, 0, 0, 0.5);
    height: 140px;

    @media (max-width: 991px) {
      position: relative;
      background: transparent;
      margin-bottom: 0;
    box-shadow: none;
    top: 0;
    height: auto;
    }
  }

  ul ul li {
    width: auto;
    min-width: 170px;
    padding: 2px 0;
    :hover{
      background: #012662;
    }
  }
  

  ul ul li a {
    padding: 5px 10px;
    text-decoration: none;
    white-space: nowrap;
  }

  @keyframes fadeInMenu {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
