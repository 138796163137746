import React, { useState } from "react"
import { Link } from "gatsby"
import { NavigationWrapper } from "./Navigation.styles"
import { AnimatePresence } from "framer-motion"
import { Nav, NavDropdown } from "react-bootstrap"

const Navigation = () => {
  const [show, setShow] = useState(false)
  const showDropdown = e => {
    setShow(!show)
    e.target.classList.add("active")
  }
  const hideDropdown = e => {
    setShow(false)
    e.target.classList.remove("active")
  }

  return (
    <NavigationWrapper>
      <AnimatePresence exitBeforeEnter>
        <ul>
          <li>
            <Link to="/">HOME</Link>
          </li>
          <li>
            <Link to="#" className="dropdown">Partner Solutions</Link>
            <ul>
              <li>
                <Link to="/ctm360">CTM360</Link>
              </li>
              <li>
                <Link to="/dmarc360">DMARC360</Link>
              </li>
              <li>
                <Link to="/pentest360">PENTEST360</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/contact">CONTACT</Link>
          </li>
          <li>
            <Link to="/request-a-demo" id="request-demo">
              REQUEST A DEMO
            </Link>
          </li>
        </ul>
      </AnimatePresence>
    </NavigationWrapper>
  )
}
export default Navigation
