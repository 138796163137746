import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import { Wrapper } from "./TopBar.styles"
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"
import { FaPhone } from "@react-icons/all-files/fa/FaPhone"
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope"


const TopBar = () => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12} className="topbar-social">
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/Advanced-Matrix-100600602428760/"
                  target="_blank"
                >
                  <FaFacebook />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/AdvancedMatrix_" target="_blank">
                  <FaTwitter />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/1322821/admin/"
                  target="_blank"
                >
                  <FaLinkedin />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/advancedmatrix/"
                  target="_blank"
                >
                  <FaInstagram />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UC_gT7JchBHhE3vyX_gOqSdA"
                  target="_blank"
                >
                  <FaYoutube />
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="topbar-contact">
            <ul className="sp-contact-info">
              <li className="sp-contact-phone">
                <FaPhone/>
                <a href="tel:+966138821434">+966 138821464</a>
              </li>
              <li className="sp-contact-email">
                <FaEnvelope/>
                <a href="mailto:info@itmatrix.com">info@itmatrix.com</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default TopBar
